<template>
  <div>

    <el-row>
      <el-col :span="4">
        <h4>Befunde</h4>
      </el-col>
      <el-col :span="20" class="text-right">
          <el-button type="warning" @click="onBtnNewFindingsClick">Neuer Befund</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">

        <el-button type="primary" @click="onBtnShowAll">Alle Befunde anzeigen</el-button>
        <el-button type="primary" @click="onBtnShowUnused">Nicht zugewiesene Befunde anzeigen</el-button>

        <el-table
            ref="findingstable"
            :data="patientFindings"
            style="width: 100%"
            highlight-current-row
            @row-click="onFindingsClick"
            stripe>
          <el-table-column
              prop="date"
              label="Datum"
              width="150"
              sortable>
            <template #default="scope">
              <span v-if="scope.row.date">{{ $filters.germanDate(scope.row.date) }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="diagnostic_method_id"
              label="Diagnostisches Verfahren">
            <template #default="scope">
              <span v-if="scope.row.meta && scope.row.meta.diagnosticmethod">{{ scope.row.meta.diagnosticmethod.title }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="evaluation"
              label="Bewertung">
            <template #default="scope">
              <span v-if="scope.row.evaluation">{{ scope.row.evaluation }}</span>
            </template>
          </el-table-column>
          <el-table-column
              width="70">
            <template #default="scope">
              <div class="text-right">

                <el-popconfirm
                    :title="'Sie möchten den Befund vom ' + $filters.germanDate(scope.row.date) + ' löschen?'"
                    confirmButtonText='Ja, löschen'
                    confirmButtonType="danger"
                    cancelButtonText='Nein'
                    iconColor="red"
                    @confirm="onDeleteFinding(scope.row)">
                  <template #reference>
                    <el-button size="mini" type="warning" icon="el-icon-delete"></el-button>
                  </template>
                </el-popconfirm>

              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-col>
    </el-row>

    <patient-finding-form-modal :finding="currentFinding" v-model="showForm"></patient-finding-form-modal>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import moment from "moment";
import PatientFindingFormModal from "./PatientFindingFormModal";
const _ = require('lodash');

export default {
  name: "PatientFindings",
  props: [],
  components: {PatientFindingFormModal},
  data() {
    return {
      patientFindings: [],
      newFindingTemplate: {
        id: 0,
        date: moment().format("YYYY-MM-DD"),
        diagnosis: [],
        progress_evaluations: [],
        patient_id: null,
        meta: {}
      },
      showForm: false,
      currentFinding: null,
      selectedDiagnosis: null,
      selectableDiagnosis: [],
      selectedProgressEvaluation: null,
      selectableProgressEvaluations: [],
    }
  },
  mounted: async function () {

    await this.getDoctors()
    await this.getTherapyUnits()
    await this.getDiagnosticMethods()

  },
  computed: {
    ...mapGetters('patients', ['currentPatient', 'unusedFindings']),
    ...mapGetters('doctors', ['doctors']),
    ...mapGetters('therapyunits', ['therapyunits']),
    ...mapGetters('diagnosticmethods', ['diagnosticmethods'])
  },
  watch: {
    'currentPatient': function() {
      this.patientFindings = _.cloneDeep(this.currentPatient.findings)
      this.selectableDiagnosis = _.cloneDeep(this.currentPatient.diagnosis)
      this.newFindingTemplate.patient_id = this.currentPatient.id

      const patientProgress = _.cloneDeep(this.currentPatient.progress)
      for(const progress of patientProgress) {
        for(const evaluation of progress.evaluations){
          evaluation.progress_title = progress.title
          this.selectableProgressEvaluations.push(evaluation)
        }
      }

    },
    'selectedDiagnosis': function(diagnosis) {

      if(diagnosis) {
        const exist = _.find(this.currentFinding.diagnosis, (o) => o.id ==diagnosis.id)
        if(exist) return

        let allDiagnosis = _.cloneDeep(this.currentFinding.diagnosis)
        allDiagnosis.push(diagnosis)

        this.currentFinding.diagnosis = allDiagnosis
        this.selectedDiagnosis = null
      }

    },
    'selectedProgressEvaluation': function(evaluation) {

      if(evaluation) {
        const exist = _.find(this.currentFinding.progress_evaluations, (o) => o.id ==evaluation.id)
        if(exist) return

        let allEvaluations = _.cloneDeep(this.currentFinding.progress_evaluations)
        allEvaluations.push(evaluation)

        this.currentFinding.progress_evaluations = allEvaluations
        this.selectedProgressEvaluation = null
      }

    },
  },
  methods: {
    ...mapActions('patients', ['saveFindingsToPatient', 'deleteFindingOfPatient', 'reloadCurrentPatient']),
    ...mapActions('diagnosis', ['getDiagnosis']),
    ...mapActions('doctors', ['getDoctors']),
    ...mapActions('therapyunits', ['getTherapyUnits']),
    ...mapActions('diagnosticmethods', ['getDiagnosticMethods']),

    onFindingsClick(row) {
      this.showForm = true
      this.currentFinding = row
    },
    onBtnNewFindingsClick() {
      this.showForm = true
      this.currentFinding = { id: 0 }
    },
    async onDeleteFinding(finding) {
      if(finding.id > 0) {
        await this.deleteFindingOfPatient({
          patient_id: finding.patient_id,
          finding_id: finding.id
        })
      }
      _.remove(this.patientFindings, (o) => o.id == finding.id)
      if(this.currentFinding && this.currentFinding.id && this.currentFinding.id == finding.id) this.currentFinding = null

      this.reloadCurrentPatient()

    },
    onDeleteProgressEvaluationFromFinding(evaluation){
      this.currentFinding.progress_evaluations = _.filter(this.currentFinding.progress_evaluations, (o) => o.id !== evaluation.id)
    },
    onDeleteDiagnosisFromFinding(diagnosis) {
      this.currentFinding.diagnosis = _.filter(this.currentFinding.diagnosis, (o) => o.id !== diagnosis.id)
    },
    onBtnShowAll() {
      this.currentFinding = null
      this.patientFindings = _.cloneDeep(this.currentPatient.findings)
    },
    onBtnShowUnused() {
      this.currentFinding = null
      this.patientFindings = _.cloneDeep(this.unusedFindings)
    }
  }
}
</script>
<style scoped>
</style>
